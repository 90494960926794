.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media screen and (max-width: 767px) {
  .root {
    padding: 0px;
    gap: 12px;
  }

  .heading {
    text-align: center;
  }
}

.heading {
  font-size: var(--font-4xl);
  font-weight: var(--font-weight-midBold);
}
